import { graphql } from "gatsby"
import Img from "gatsby-image"
import React from "react"

import Layout from "../components/layout"
import Hero from "../components/hero"
import Section from "../components/section"
import Columns from "../components/columns"
import SEO from "../components/seo"
import Headline from "../components/headline"
import P from "../components/paragraph"
import LanguageSwitch from "../components/languageSwitch"

import Logo from "../images/sumway.svg"
import Evidensia from "../images/evidensia.svg"

import styles from "./index.module.css"

export const query = graphql`
  query {
    bg: file(relativePath: { eq: "bg.jpg" }) {
      childImageSharp {
        fluid(quality: 75) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    memira: file(relativePath: { eq: "memira.png" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    svalorna: file(relativePath: { eq: "svalorna.png" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`

const IndexPage = ({ data }) => {
  return (
    <Layout>
      <SEO
        lang="en"
        title="Blazing fast websites ⚡ Sumway"
        description="Sumway are building lightning fast websites with Wordpress, React and other frameworks. We have over 15 years of experience with web development."
      />
      <Hero
        bg={
          <Img
            fluid={{ ...data.bg.childImageSharp.fluid, sizes: "100vw" }}
            objectFit="cover"
            className={styles.heroImage}
            style={{ position: "absolute" }}
          />
        }
      >
        <div className={styles.heroInner}>
          <Headline tagName="h1" className={styles.heroHeadline}>
            <Logo
              style={{ width: "70%", maxWidth: "80rem", margin: "auto" }}
              title="Sumway"
            />
          </Headline>
        </div>
        <LanguageSwitch />
      </Hero>
      <Section
        title={
          <>
            Hello, new friend{" "}
            <span role="img" aria-label="waving" className={styles.wavingHand}>
              👋
            </span>
          </>
        }
        textAlign="center"
        bgColor="grey"
        className={styles.wavingHandParent}
      >
        <P style={{ padding: "0 1em" }}>
          If you're interested in high-end blazing fast websites, you are at the
          right place.
        </P>
      </Section>
      <Section
        title="It's not complicated"
        textAlign="center"
        className={styles.balloonParent}
      >
        <Columns>
          <>
            <Headline>
              Users will not{" "}
              <span className={styles.inlineBlock}>wait for your site</span>
            </Headline>
            <P>
              A waiting time above 3 seconds makes you loose ~30% of your
              visitors.
            </P>
          </>
          <>
            <Headline>
              Design for{" "}
              <span className={styles.inlineBlock}>mobile devices first</span>
            </Headline>
            <P>
              More than 50% of your visitors are mobile users &ndash; and they
              are getting more every day. Deal with it.
            </P>
          </>
          <>
            <Headline>
              Advanced is{" "}
              <span className={styles.inlineBlock}>not equal complicated</span>
            </Headline>
            <P>
              <>
                Don't use dark design patterns on your website. Make it easy
                (&amp; fun{" "}
                <span
                  role="img"
                  aria-label="balloon"
                  className={styles.balloon}
                >
                  🎈
                </span>
                ) for your visitors.
              </>
            </P>
          </>
        </Columns>
      </Section>
      <Section title="Our experience" bgColor="grey" textAlign="center">
        <Columns>
          <>
            <Headline>WordPress</Headline>
            <P>Over 10 years of experience building custom themes.</P>
          </>
          <>
            <Headline>JavaScript &amp; PHP</Headline>
            <P>Deep knowledge of component based development.</P>
          </>
          <>
            <Headline>CSS</Headline>
            <P>Using next level CSS with fallback support for old browsers.</P>
          </>
          <>
            <Headline>React</Headline>
            <P>Experience with breaking down components into reusable parts.</P>
          </>
          <>
            <Headline>Git</Headline>
            <P>
              Always using version control &amp; feature branches when
              necessary.
            </P>
          </>
          <>
            <Headline>Laravel &amp; Lumen</Headline>
            <P>For building API:s or sites with very specific functionality.</P>
          </>
        </Columns>
      </Section>
      <Section title="Awesome clients" textAlign="center">
        <Columns gutters="large" vAlign="center">
          <a
            href="https://svalorna.org"
            target="_blank"
            rel="noopener noreferrer"
            style={{ opacity: ".8" }}
          >
            <Img
              fluid={{ ...data.svalorna.childImageSharp.fluid }}
              className={styles.clientImage}
            />
          </a>
          <a
            href="https://www.memira.se"
            target="_blank"
            rel="noopener noreferrer"
            style={{ opacity: ".7" }}
          >
            <Img
              fluid={data.memira.childImageSharp.fluid}
              className={styles.clientImage}
            />
          </a>
          <a
            href="https://evidensia.se"
            target="_blank"
            rel="noopener noreferrer"
            style={{ opacity: ".7" }}
          >
            <Evidensia className={styles.clientImage} />
          </a>
        </Columns>
      </Section>
      <Section title="Contact us" textAlign="center" bgColor="grey">
        <Columns>
          <>
            <Headline>E-mail</Headline>
            <P>
              <a href="mailto:hello@sumway.se">hello@sumway.se</a>
            </P>
          </>
          <>
            <Headline>Phone</Headline>
            <P>
              <a href="tel:+46738046446">+46 (0) 73-804 64 46</a>
            </P>
          </>
          <>
            <Headline>LinkedIn</Headline>
            <P>
              <a
                href="https://bit.ly/2mbJYVi"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://bit.ly/2mbJYVi
              </a>
            </P>
          </>
        </Columns>
      </Section>
    </Layout>
  )
}

export default IndexPage
